import React, { useState, useEffect, useMemo } from 'react';
import mqtt from 'mqtt';
import DataTable, { ExpanderComponentProps, defaultThemes } from 'react-data-table-component';

export default function Brokerstatus() {
	const [isConnected, setIsConnected] = useState(false);
	const [client, setClient] = useState(null);
	const [loading, setLoading] = useState(true);
	const [bytesSent, setBytesSent] = useState(0);
	const [bytesReceived, setBytesReceived] = useState(0);
	const [brokerVersion, setBrokerVersion] = useState('');
	const [totalClients, setTotalClients] = useState(0);
	const [uptime, setUptime] = useState('');
	const [messagesReceived, setMessagesReceived] = useState(0);
	const [messagesSent, setMessagesSent] = useState(0);
	const [messagesStoredCount, setMessagesStoredCount] = useState(0);
	const [subscriptionsCount, setSubscriptionsCount] = useState(0);
	const [retainedCount, setRetainedCount] = useState(0);
	const [messageLoadReceived1Min, setMessageLoadReceived1Min] = useState(0);
	const [messageLoadSent1Min, setMessageLoadSent1Min] = useState(0);
	const [maximumClients, setMaximumClients] = useState(0);
	const [activeClients, setActiveClients] = useState(0);
	const [inactiveClients, setInactiveClients] = useState(0);
	const [disconnectedClients, setDisconnectedClients] = useState(0);
	const [messageLoad5Min, setMessageLoad5Min] = useState({ received: 0, sent: 0 });
	const [messageLoad15Min, setMessageLoad15Min] = useState({ received: 0, sent: 0 });
	const [heapUsage, setHeapUsage] = useState({ current: 0, maximum: 0 });
	const [bytesLoad, setBytesLoad] = useState({
		received: { '1min': 0, '5min': 0, '15min': 0 },
		sent: { '1min': 0, '5min': 0, '15min': 0 }
	});
	const [messageArrived, setMessageArrived] = useState(false);

	const onMessageArrived = useMemo(() => {
		return (message) => {
			setMessageArrived(true);
			setTimeout(() => setMessageArrived(false), 200);

			const topic = message.destinationName;
			const payload = message.payloadString;
			
			// Handle specific topics
			if (topic === '$SYS/broker/publish/bytes/sent') {
				setBytesSent(parseInt(payload));
			} else if (topic === '$SYS/broker/publish/bytes/received') {
				setBytesReceived(parseInt(payload));
			} else if (topic === '$SYS/broker/version') {
				setBrokerVersion(payload);
			} else if (topic === '$SYS/broker/clients/total') {
				setTotalClients(parseInt(payload));
			} else if (topic === '$SYS/broker/messages/received') {
				setMessagesReceived(parseInt(payload));
			} else if (topic === '$SYS/broker/messages/sent') {
				setMessagesSent(parseInt(payload));
			} else if (topic === '$SYS/broker/uptime') {
				// Convert seconds to a more readable format
				const seconds = parseInt(payload);
				const days = Math.floor(seconds / 86400);
				const hours = Math.floor((seconds % 86400) / 3600);
				const minutes = Math.floor((seconds % 3600) / 60);
				const uptimeStr = `${days}d ${hours}h ${minutes}m`;
				setUptime(uptimeStr);
			} else if (topic === '$SYS/broker/store/messages/count') {
				setMessagesStoredCount(parseInt(payload));
			} else if (topic === '$SYS/broker/subscriptions/count') {
				setSubscriptionsCount(parseInt(payload));
			} else if (topic === '$SYS/broker/retained messages/count') {
				setRetainedCount(parseInt(payload));
			} else if (topic === '$SYS/broker/load/messages/received/1min') {
				setMessageLoadReceived1Min(parseFloat(payload));
			} else if (topic === '$SYS/broker/load/messages/sent/1min') {
				setMessageLoadSent1Min(parseFloat(payload));
			} else if (topic === '$SYS/broker/clients/maximum') {
				setMaximumClients(parseInt(payload));
			} else if (topic === '$SYS/broker/clients/active') {
				setActiveClients(parseInt(payload));
			} else if (topic === '$SYS/broker/clients/inactive') {
				setInactiveClients(parseInt(payload));
			} else if (topic === '$SYS/broker/clients/disconnected') {
				setDisconnectedClients(parseInt(payload));
			} else if (topic === '$SYS/broker/load/messages/received/5min') {
				setMessageLoad5Min(prev => ({ ...prev, received: parseFloat(payload) }));
			} else if (topic === '$SYS/broker/load/messages/sent/5min') {
				setMessageLoad5Min(prev => ({ ...prev, sent: parseFloat(payload) }));
			} else if (topic === '$SYS/broker/load/messages/received/15min') {
				setMessageLoad15Min(prev => ({ ...prev, received: parseFloat(payload) }));
			} else if (topic === '$SYS/broker/load/messages/sent/15min') {
				setMessageLoad15Min(prev => ({ ...prev, sent: parseFloat(payload) }));
			} else if (topic === '$SYS/broker/heap/current') {
				setHeapUsage(prev => ({ ...prev, current: parseInt(payload) }));
			} else if (topic === '$SYS/broker/heap/maximum') {
				setHeapUsage(prev => ({ ...prev, maximum: parseInt(payload) }));
			}
		};
	}, []);

	useEffect(() => {
		const mqttClient = mqtt.connect('wss://interceptor.skylab.nl:8883', {
			clientId: `client_${Math.random().toString(16).substring(2, 8)}`,
			keepalive: 30,
			clean: true,
			reconnectPeriod: 5000,
			connectTimeout: 30 * 1000,
			//rejectUnauthorized: false,
			protocolVersion: 4,
			ssl: true
		});

		mqttClient.on('connecting', () => {
			//console.log('Connecting to MQTT broker...');
		});

		mqttClient.on('connect', () => {
			//console.log('MQTT Connected');
			setIsConnected(true);
			setLoading(false);
			
			// Subscribe to system topic
			mqttClient.subscribe('$SYS/broker/#', (err) => {
				if (err) {
					console.error('Subscription error:', err);
				}
			});
		});

		mqttClient.on('message', (topic, message) => {
			onMessageArrived({
				destinationName: topic,
				payloadString: message.toString()
			});
			//console.log(topic)
		});

		mqttClient.on('error', async (err) => {
			setIsConnected(false);
			setLoading(true);
			mqttClient.reconnect();
		});

		mqttClient.on('close', async () => {
			setIsConnected(false);
			setLoading(true);

		});

		setClient(mqttClient);

		return () => {
			if (mqttClient) {
				mqttClient.end();
			}
		};
	}, []);  // Remove accountdevices dependency

	const columns = useMemo(() => [
		{
			name: 'Field',
			selector: row => row.metric,
			sortable: true,
		},
		{
			name: 'Value',
			selector: row => row.value,
			sortable: true,
		},
	], []);

	const data = useMemo(() => [
		{
			metric: 'Broker Status',
			value: isConnected ? 'Connected' : 'Disconnected',
			className: isConnected ? 'connected' : 'disconnected',
			isMessageArrived: messageArrived
		},
		{
			metric: 'Bytes Sent',
			value: bytesSent.toLocaleString()
		},
		{
			metric: 'Bytes Received',
			value: bytesReceived.toLocaleString()
		},
		{
			metric: 'Messages Received',
			value: messagesReceived.toLocaleString()
		},
		{
			metric: 'Messages Sent',
			value: messagesSent.toLocaleString()
		},
		{
			metric: 'Broker Version',
			value: brokerVersion
		},
		{
			metric: 'Total Clients',
			value: totalClients.toString()
		},
		{
			metric: 'Uptime',
			value: uptime
		},
		{
			metric: 'Messages Stored',
			value: messagesStoredCount.toLocaleString()
		},
		{
			metric: 'Active Subscriptions',
			value: subscriptionsCount.toString()
		},
		{
			metric: 'Retained Messages',
			value: retainedCount.toString()
		},
		{
			metric: 'Message Load (Received/1min)',
			value: messageLoadReceived1Min.toFixed(2)
		},
		{
			metric: 'Message Load (Sent/1min)',
			value: messageLoadSent1Min.toFixed(2)
		},
		{
			metric: 'Maximum Clients',
			value: maximumClients.toString()
		},
		{
			metric: 'Active Clients',
			value: activeClients.toString()
		},
		{
			metric: 'Inactive Clients',
			value: inactiveClients.toString()
		},
		{
			metric: 'Disconnected Clients',
			value: disconnectedClients.toString()
		},
		{
			metric: 'Message Load (Received/5min)',
			value: messageLoad5Min.received.toFixed(2)
		},
		{
			metric: 'Message Load (Sent/5min)',
			value: messageLoad5Min.sent.toFixed(2)
		},
		{
			metric: 'Message Load (Received/15min)',
			value: messageLoad15Min.received.toFixed(2)
		},
		{
			metric: 'Message Load (Sent/15min)',
			value: messageLoad15Min.sent.toFixed(2)
		},
		{
			metric: 'Heap Usage',
			value: `${(heapUsage.current / 1024).toFixed(2)}KB / ${(heapUsage.maximum / 1024).toFixed(2)}KB`
		}
	], [isConnected, messageArrived, bytesSent, bytesReceived, brokerVersion, totalClients, uptime, messagesReceived, messagesSent, messagesStoredCount, subscriptionsCount, retainedCount, messageLoadReceived1Min, messageLoadSent1Min, maximumClients, activeClients, inactiveClients, disconnectedClients, messageLoad5Min, messageLoad15Min, heapUsage]);

	return (
		<div className="broker-status-container">
			<DataTable
				columns={columns}
				data={data}
				dense
				highlightOnHover
				responsive
				conditionalRowStyles={[
					{
						when: row => row.className === 'connected',
						style: { color: 'green' },
					},
					{
						when: row => row.className === 'disconnected',
						style: { color: 'red' },
					},
					{
						when: row => row.isMessageArrived,
						style: { backgroundColor: 'lightgray' },
					},
				]}
			/>
		</div>
	);
}