import React from 'react';
import './App.css';
import Dashboard from './dashboard';
import Login from './login';
import useToken from './useToken';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';

function App() {

 const { token, setToken } = useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <BrowserRouter>
      <div className="wrapper">
        <Dashboard />
      </div>
    </BrowserRouter>
  );
}

export default App;