import React, { useState } from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { useMap } from 'react-leaflet/hooks'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import 'leaflet/dist/leaflet.css';
import './multimapbase.css';
import './mapbase.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Icon } from 'leaflet';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

function RecenterAutomatically({ lat, lng }) {
  const map = useMap();
  React.useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng, map]);
  return null;
}

function calculateBounds(iconPosition) {
  if (!iconPosition || iconPosition.length === 0) return null;

  // Validate coordinates first
  const validMarkers = iconPosition.filter(marker => 
    marker.lat && marker.long && 
    !isNaN(marker.lat) && !isNaN(marker.long) &&
    Math.abs(marker.lat) <= 90 && Math.abs(marker.long) <= 180
  );

  if (validMarkers.length === 0) return null;

  // Initialize with first valid position
  let maxN = validMarkers[0].lat;
  let maxS = validMarkers[0].lat;
  let maxE = validMarkers[0].long;
  let maxW = validMarkers[0].long;

  // Find bounds
  validMarkers.forEach(marker => {
    maxN = Math.max(maxN, marker.lat);
    maxS = Math.min(maxS, marker.lat);
    maxE = Math.max(maxE, marker.long);
    maxW = Math.min(maxW, marker.long);
  });

  // Add padding to bounds (about 10% on each side)
  const latPadding = (maxN - maxS) * 0.1;
  const lngPadding = (maxE - maxW) * 0.1;

  maxN += latPadding;
  maxS -= latPadding;
  maxE += lngPadding;
  maxW -= lngPadding;

  return {
    bounds: {
      ne: { lat: maxN, long: maxE },
      sw: { lat: maxS, long: maxW }
    },
    center: {
      lat: (maxN + maxS) / 2,
      long: (maxE + maxW) / 2
    }
  };
}

function FitBounds({ bounds }) {
  const map = useMap();
  
  React.useEffect(() => {
    if (bounds && bounds.ne && bounds.sw) {
      // Set bounds with padding
      map.fitBounds([
        [bounds.ne.lat, bounds.ne.long],
        [bounds.sw.lat, bounds.sw.long]
      ], {
        padding: [50, 50], // Add 50 pixels padding
        maxZoom: 18 // Limit maximum zoom level
      });
    }
  }, [bounds, map]);
  
  return null;
}

// Add this helper function to map HTML colors to marker colors
function getMarkerColor(htmlColor) {
  // Convert HTML color to lowercase for comparison
  const color = htmlColor.toLowerCase();
  
  // Map HTML colors to available marker colors
  switch (color) {
    case '#ff0000': return 'red';
    case '#00ff00': return 'green';
    case '#0000ff': return 'blue';
    case '#ffa500': return 'orange';
    case '#800080': return 'violet';
    case '#ffc0cb': return 'pink';
    case '#008000': return 'green';
    case '#ffd700': return 'yellow';
    case '#4b0082': return 'violet';
    case '#ff1493': return 'pink';
    case '#00ffff': return 'blue';
    case '#ff4500': return 'red';
    case '#9400d3': return 'violet';
    case '#32cd32': return 'green';
    case '#ff69b4': return 'pink';
    case '#1e90ff': return 'blue';
    case '#8b4513': return 'red';
    case '#7cfc00': return 'green';
    case '#ff8c00': return 'orange';
    case '#483d8b': return 'violet';
    default: return 'blue';  // default color if no match
  }
}

export default function MapBase({ iconPosition }) {

  const [userInteraction, setUserInteraction] = React.useState(false);
  const [lastValidPosition, setLastValidPosition] = useState([51.505, -0.09]);
  const [lastValidPopperContent, setLastValidPopperContent] = useState('No data available');
  const [lastValidData, setLastValidData] = useState({
    udi: 'N/A',
    version: 'N/A',
    status: 'N/A',
    heartbeat: 'N/A',
    alt: 'N/A',
    spd: 'N/A',
    fix: 'N/A',
    sats: 'N/A',
    gpstime: 'N/A',
    gsm: 'N/A',
    ble_cnt: 'N/A',
    acc: 'N/A',
    voltage: 'N/A',
    batt: 'N/A'
  });

  // Update initial position calculation with validation
  const initialPosition = iconPosition?.[0]?.lat && iconPosition[0]?.long && 
    !isNaN(iconPosition[0].lat) && !isNaN(iconPosition[0].long) &&
    Math.abs(iconPosition[0].lat) <= 90 && Math.abs(iconPosition[0].long) <= 180
      ? [iconPosition[0].lat, iconPosition[0].long]
      : lastValidPosition;

  // Create popup content function for each marker
  const createPopperContent = (markerData) => (
    <div>
      <h4>Device Info</h4>
      <p><strong>ID:</strong> {markerData.udi || lastValidData.udi}</p>
      <p><strong>Status:</strong> {markerData.status || lastValidData.status}</p>
      <p><strong>Heartbeat:</strong> {markerData.heartbeat ? markerData.heartbeat : lastValidData.heartbeat}</p>
      <p><strong>Version:</strong> {markerData.version ? markerData.version : lastValidData.version}</p>

      <h4>Location</h4>
      <p><strong>Latitude:</strong> {markerData.lat ? markerData.lat : lastValidPosition.lat}°</p>
      <p><strong>Longitude:</strong> {markerData.long ? markerData.long : lastValidPosition.long}°</p>
      <p><strong>Altitude:</strong> {markerData.alt ? markerData.alt : lastValidData.alt} m</p>
      <p><strong>Speed:</strong> {markerData.spd ? markerData.spd : lastValidData.spd} km/h</p>

      <h4>Signal Info</h4>
      <p><strong>GPS Fix:</strong> {markerData.fix ? markerData.fix : lastValidData.fix}</p>
      <p><strong>Satellites:</strong> {markerData.sats ? markerData.sats : lastValidData.sats}</p>
      {/* <p><strong>GPS Time:</strong> {iconPosition[0].gpstime ? iconPosition[0].gpstime : lastValidData.gpstime}</p> */}
      <p><strong>GSM Signal:</strong> {markerData.gsm ? markerData.gsm : lastValidData.gsm}</p>
      <p><strong>BLE Devices:</strong> {markerData.ble_cnt ? markerData.ble_cnt : lastValidData.ble_cnt}</p>
      <p><strong>Accuracy:</strong> {markerData.acc ? markerData.acc : lastValidData.acc} m</p>

      <h4>Power Info</h4>
      <p><strong>Battery:</strong> {markerData.batt ? markerData.batt : lastValidData.batt} %</p>
      <p><strong>Voltage:</strong> {markerData.voltage ? markerData.voltage : lastValidData.voltage} Volt</p>
    </div>
  );

  // Store the last valid values
  React.useEffect(() => {
    if (iconPosition?.[0]) {
      const newValidData = {};
      Object.keys(lastValidData).forEach(key => {
        const newValue = iconPosition[0][key];
        const currentValue = lastValidData[key];

        // Keep previous valid value if new value is 0 or null
        if (newValue != null && newValue !== 0) {
          newValidData[key] = newValue;
        } else if (currentValue != null && currentValue !== 'N/A' && currentValue !== 0) {
          newValidData[key] = currentValue;
        }
      });

      if (Object.keys(newValidData).length > 0) {
        setLastValidData(prev => ({ ...prev, ...newValidData }));
      }
    }
  }, [iconPosition]);

  // Store the new position if it's valid
  React.useEffect(() => {
    if (iconPosition?.[0]?.lat && iconPosition[0]?.long) {
      setLastValidPosition([iconPosition[0].lat, iconPosition[0].long]);
    }
  }, [iconPosition]);

  const boundsData = React.useMemo(() => calculateBounds(iconPosition), [iconPosition]);

  // Add event handlers for map
  const HandleMapEvents = () => {
    const map = useMap();
    
    React.useEffect(() => {
      const onMoveOrZoom = () => {
        setUserInteraction(true);
      };

      map.on('movestart', onMoveOrZoom);
      map.on('zoomstart', onMoveOrZoom);

      // Cleanup event listeners
      return () => {
        map.off('movestart', onMoveOrZoom);
        map.off('zoomstart', onMoveOrZoom);
      };
    }, [map]);

    return null;
  };

  // Add click handler for Autozoom button
  const handleAutozoom = () => {
    setUserInteraction(false);
  };

  return (
    <div className="map-container">
      {userInteraction && (
        <button 
          id="btnAutozoom" 
          className="btn btn-primary"
          onClick={handleAutozoom}
        >
          Set back to autozoom
        </button>
      )}
      <MapContainer center={initialPosition} zoom={13} scrollWheelZoom={true}>
        {!userInteraction && boundsData && <FitBounds bounds={boundsData.bounds} />}
        {!userInteraction && <RecenterAutomatically lat={initialPosition[0]} lng={initialPosition[1]} />}
        <HandleMapEvents />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {iconPosition && iconPosition.map((marker, index) => (
          marker.lat && marker.long && 
          !isNaN(marker.lat) && !isNaN(marker.long) &&
          Math.abs(marker.lat) <= 90 && Math.abs(marker.long) <= 180 && (
            <Marker
              key={`${marker.udi}-${index}`}
              position={[marker.lat, marker.long]}
              icon={new Icon({
                iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(marker.markerColor)}.png`,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
              })}
            >
              <Popup>
                {createPopperContent(marker)}
              </Popup>
            </Marker>
          )
        ))}
      </MapContainer>
    </div>
  );
}