//https://github.com/ms-aija/LeafletReact5minDemo/blob/master/src/components/Map.js#L22

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import MultiMapBase from './multimapbase';
import mqtt from 'mqtt';
import "./multimap.css"

import loadlogo from './images/loading.gif';

const HTML_COLORS = [
  '#FF0000', // Red
  '#00FF00', // Lime
  '#0000FF', // Blue
  '#FFA500', // Orange
  '#800080', // Purple
  '#FFC0CB', // Pink
  '#008000', // Green
  '#FFD700', // Gold
  '#4B0082', // Indigo
  '#FF1493', // Deep Pink
  '#00FFFF', // Cyan
  '#FF4500', // Orange Red
  '#9400D3', // Dark Violet
  '#32CD32', // Lime Green
  '#FF69B4', // Hot Pink
  '#1E90FF', // Dodger Blue
  '#8B4513', // Saddle Brown
  '#7CFC00', // Lawn Green
  '#FF8C00', // Dark Orange
  '#483D8B'  // Dark Slate Blue
];

const position = [51, 4];
// --- (6) Create a custom marker ---
const customIconBlue = new Icon({
  iconUrl: 'https://login.iotdash.nl/images/Markers/Origineel/marker-icon-green.png',
  iconSize: [24, 41],
  popupAnchor: [0, -10]
})

export default function MultiMap() {

  const [devudi, setDevudi] = useState('')
  const [iconPosition, seticonPosition] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [center, setCenter] = useState([52.1460977688048, 5.450848556067135]);
  const [zoom, setZoom] = useState(6);
  const [client, setClient] = useState(null);
  const [accountdevices, setAccountdevices] = useState([]);
  const [showmap, setShowmap] = useState(false);
  const [dataaccInt, setDataaccInt] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns_interceptor_account = [
    {
      name: 'iMei',
      selector: row => row.udi,
      sortable: true,
      left: true,
      width: '20%'
    },
    {
      name: 'Seal',
      selector: row => row.seal,
      sortable: true,
      left: true,
      width: '20%'
    },
    {
      name: 'Devicename',
      selector: row => row.devicename,
      sortable: true,
      left: true,
      width: '20%'
    },
    {
      name: 'customerdeviceid',
      selector: (row) => row.customerdeviceid,
      width: '0',
      left: true,
    },
    {
      name: 'accountdeviceid',
      selector: (row) => row.accountdeviceid,
      width: '0',
      left: true,
    },
    {
      name: 'bleaccountid',
      selector: (row) => row.bleaccountid,
      width: '0',
      left: true,
    },
    {
      name: 'color',
      selector: (row, index) => HTML_COLORS[index % HTML_COLORS.length],
      width: '100',
      left: true,
      cell: (row, index) => (
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: row.markerColor || HTML_COLORS[index % HTML_COLORS.length],
            borderRadius: '50%'
          }}
        />
      )
    }
  ];

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    if (selectedRows.length > 0) {
      setShowmap(true);

      // Get all currently selected UDIs with their colors
      const selectedUdisWithColors = selectedRows.map(row => ({
        udi: row.udi,
        markerColor: row.markerColor || HTML_COLORS[dataaccInt.indexOf(row) % HTML_COLORS.length]
      }));

      if (client) {
        // Get all UDIs from the current data
        const currentUdis = dataaccInt.map(device => device.udi);
        // Unsubscribe from each UDI
        currentUdis.forEach(udi => {
          client.unsubscribe(`interceptor/${udi}/#`);
        });

        // Remove markers for deselected devices
        seticonPosition(prevPositions => 
          prevPositions.filter(pos => selectedUdisWithColors.some(selected => selected.udi === pos.udi))
        );
      }

      // Then subscribe to newly selected topics
      selectedUdisWithColors.forEach(({ udi }) => {
        client.subscribe(`interceptor/${udi}/#`, {
          qos: 0,
          onFailure: (err) => console.error("Subscribe failed:", err),
          onSuccess: () => setLoading(false)
        });
      });

      // Store the color mapping for use in onMessageArrived
      window.markerColors = Object.fromEntries(
        selectedUdisWithColors.map(({ udi, markerColor }) => [udi, markerColor])
      );
    }
    else {
      setDevudi('');
      setShowmap(false);
      seticonPosition([]);
      window.markerColors = {};
    }
  };

async function getAccountdevices() {
  setLoading(true);
  const items = JSON.parse(localStorage.getItem('token'));
  try {
    const response = await fetch('https://interceptor.skylab.nl:2807/interceptormqtt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ accountid: items.id })
    })
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    const data = await response.json();

    setDataaccInt(data);
    setDevudi(''); // Clear current UDI selections
    setShowmap(false); // Hide the map
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
}

const interceptors = {};
const onMessageArrived = (message) => {
  try {
    const topic = message.destinationName;
    const payload = message.payloadString;

    const parts = topic.split('/');
    if (parts.length >= 2) {
      const imei = parts[1];

      if (!interceptors[imei]) {
        interceptors[imei] = {
          ble_cnt: null,
          fix: null,
          acc: null,
          gpstime: null,
          version: null,
          batt: null,
          gsm: null,
          lat: null,
          long: null,
          spd: null,
          alt: null,
          sats: null,
          heartbeat: 'online'
        };
      }

      const safePayload = payload !== null && payload !== undefined ? payload : null;

      if (parts[2]) {  // Check if we have a third part in the topic
        if (parts[3]) {  // If we have a fourth part, it's a standard metric
          switch (parts[3]) {
            case 'ble_cnt': interceptors[imei].ble_cnt = safePayload; break;
            case 'fix': interceptors[imei].fix = safePayload; break;
            case 'acc': interceptors[imei].acc = safePayload; break;
            case 'gpstime': interceptors[imei].gpstime = safePayload; break;
            case 'version': interceptors[imei].version = safePayload; break;
            case 'batt': interceptors[imei].batt = safePayload; break;
            case 'gsm': interceptors[imei].gsm = safePayload; break;
            case 'lat': interceptors[imei].lat = safePayload; break;
            case 'long': interceptors[imei].long = safePayload; break;
            case 'spd': interceptors[imei].spd = safePayload; break;
            case 'alt': interceptors[imei].alt = safePayload ? (parseFloat(safePayload) / 10).toFixed(2) : null; break;
            case 'sats': interceptors[imei].sats = safePayload; break;
            case 'heartbeat': interceptors[imei].heartbeat = safePayload || 'online'; break;
          }
        }
      }

      const interceptorArray = [
        {
          udi: imei,
          acc: interceptors[imei]['acc'],
          fix: interceptors[imei]['fix'],
          version: interceptors[imei]['version'],
          voltage: interceptors[imei]['batt'],
          batt: calculateBatteryPercentage(interceptors[imei]['batt']),
          gsm: interceptors[imei]['gsm'],
          ble_cnt: interceptors[imei]['ble_cnt'],
          lat: interceptors[imei]['lat'],
          long: interceptors[imei]['long'],
          sats: interceptors[imei]['sats'],
          alt: interceptors[imei]['alt'],
          spd: interceptors[imei]['spd'],
          heartbeat: interceptors[imei]['heartbeat'],
          gpstime: interceptors[imei]['gpstime'],
          status: interceptors[imei]['heartbeat'],
        }
      ];

      if (interceptorArray.length > 0) {
        const newPosition = {
          lat: parseFloat(interceptorArray[0].lat) || 0,
          lng: parseFloat(interceptorArray[0].long) || 0,
          markerColor: window.markerColors[imei],
          ...interceptorArray[0]
        };
        
        seticonPosition(prevPositions => {
          const existingIndex = prevPositions.findIndex(pos => pos.udi === imei);
          
          if (existingIndex >= 0) {
            const updatedPositions = [...prevPositions];
            updatedPositions[existingIndex] = newPosition;
            return updatedPositions;
          } else {
            return [...prevPositions, newPosition];
          }
        });
      }
    }
  } catch (error) {
    //console.error('Error processing MQTT message:', error);
  }
};


useEffect(() => {
  getAccountdevices();

  // Create MQTT.js client
  const mqttClient = mqtt.connect('wss://interceptor.skylab.nl:8883', {
    clientId: `client_${Math.random().toString(16).substring(2, 8)}`,
    keepalive: 30,
    clean: true,
    reconnectPeriod: 5000,
    connectTimeout: 30 * 1000,
    //rejectUnauthorized: false,
    protocolVersion: 4,
    ssl: true
});

  mqttClient.on('connect', () => {
    setIsConnected(true);
    // Subscribe to initial device if one is selected
    if (devudi) {
      mqttClient.subscribe(`interceptor/${devudi}/#`, { qos: 0 }, (err) => {
        if (err) console.error("Subscribe failed:", err);
        setLoading(false);
      });
    }
  });

  mqttClient.on('message', (topic, message) => {
    // Convert Buffer to string and pass to existing handler
    onMessageArrived({ 
      destinationName: topic, 
      payloadString: message.toString() 
    });
  });

  mqttClient.on('error', (err) => {
    console.error('MQTT Connection error:', err);
    setIsConnected(false);
  });

  mqttClient.on('close', () => {
    setIsConnected(false);
  });

  setClient(mqttClient);

  return () => {
    if (mqttClient) {
      mqttClient.end();
      setIsConnected(false);
    }
  };
}, [accountdevices]);

useEffect(() => {
  if (!isConnected) {
    //console.log('Waiting for MQTT connection...');
  }
}, [isConnected]);

function calculateBatteryPercentage(voltage) {
  const maxVoltage = 4.2;
  const minVoltage = 3.5;

  if (voltage >= maxVoltage) {
    return 100;
  } else if (voltage <= minVoltage) {
    return 0;
  } else {
    // Lineaire interpolatie om het percentage te berekenen
    return (((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100).toFixed(0);
  }
}

return (
  <>
      <div style={{ width: '40%' }}>
        <DataTable
          columns={columns_interceptor_account}
          data={dataaccInt}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination={true}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </div>

      {loading && (
          <p>Loading...
            <img src={loadlogo} height={50} width={50} alt="Loading" />
          </p>
      )}

    {showmap && (
      <MultiMapBase
        iconPosition={iconPosition}
        zoom={zoom}
        center={center}
        selectedMarker={selectedMarker}
      />
    )}
  </>
)
}

