//https://github.com/ms-aija/LeafletReact5minDemo/blob/master/src/components/Map.js#L22

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import L from 'leaflet';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faMap } from '@fortawesome/free-regular-svg-icons';

import "./historymap.css"

import loadlogo from './images/loading.gif';

const position = [51, 4]

const createCustomIcon = (color) => {
  return new Icon({
    iconUrl: `https://login.iotdash.nl/images/Markers/Origineel/marker-icon-${color}.png`,
    iconSize: [24, 41],
    popupAnchor: [0, -10]
  });
};

function ChangeView({ positions }) {
  const map = useMap();

  useEffect(() => {
    if (positions && positions.length > 0) {
      const bounds = L.latLngBounds();
      positions.forEach(position => {
        // Only extend bounds with valid coordinates
        if (position.lat && position.lng && 
            !isNaN(position.lat) && !isNaN(position.lng) &&
            position.lat !== 0 && position.lng !== 0) {
          bounds.extend([position.lat, position.lng]);
        }
      });

      if (!bounds.isValid()) return;
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [positions, map]);

  return null;
}

function MapReference() {
  const map = useMap();
  // Store the map instance in a ref that can be accessed globally
  window.leafletMap = map;
  return null;
}

export default function Historymap() {
  const location = useLocation();
  const [udi, setUdi] = useState([]);
  const [iconPosition, seticonPosition] = useState([]);
  const [loading, setLoading] = useState(false);
  const [udiColors] = useState(() => {
    const colors = ['red', 'green', 'purple', 'orange', 'yellow', 'pink', 'gray', 'black', 'white'];
    const colorMap = new Map();
    let colorIndex = 0;

    return {
      getColor: (udi) => {
        if (!colorMap.has(udi)) {
          colorMap.set(udi, colors[colorIndex % colors.length]);
          colorIndex++;
        }
        return colorMap.get(udi);
      }
    };
  });

  useEffect(() => {
    const selectedMac = localStorage.getItem('selectedMac');
    if (selectedMac) {
      const macInput = document.getElementById('macaddress');
      if (macInput) {
        macInput.value = selectedMac;
        // Trigger the history search automatically
        handleHistory(new Event('click'));
      }
      // Clear the stored MAC after using it
      localStorage.removeItem('selectedMac');
    }
  }, []);

  async function getHistory(information) {

    return fetch('https://interceptor.skylab.nl:2807/historymqttmac', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(information)
    })
      .then(data => data.json())
  }

  const handleHistory = async e => {
    e.preventDefault();

    const macaddress = document.getElementById('macaddress').value;

    // Stricter MAC address validation
    if (macaddress.split(':').length !== 6) {
      alert('MAC address must contain exactly 6 groups separated by colons');
      return;
    }

    const macRegex = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;
    if (!macRegex.test(macaddress)) {
      alert('Please enter a valid MAC address.');
      return;
    }

    setLoading(true)
    const result = await getHistory({
      mac: macaddress
    });

    const positions = [];
    let lastTimestamp = null;

    result.forEach((value, index) => {
      const currentTimestamp = moment(value.created_at).format('HH:mm:ss DD-MM-YY');
      
      // Skip if timestamp is the same as the previous one
      if (currentTimestamp !== lastTimestamp) {
        // Convert coordinates to numbers and validate them
        const lat = parseFloat(value.device_latitude);
        const lng = parseFloat(value.device_longitude);
        
        // Only add position if coordinates are valid
        if (!isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0) {
          positions.push({
            lat: lat,
            lng: lng,
            date: currentTimestamp,
            rssi: value.rssi,
            txpwr: value.txpwr,
            udi: value.interceptor_imei,
            deviceName: value.device_name || 'Unknown Device',
            i: value.bledataid
          });
          lastTimestamp = currentTimestamp;
        }
      }
    });

    // Only update positions if we have valid coordinates
    if (positions.length > 0) {
      seticonPosition(positions);
    } else {
      alert('No valid coordinates found in the data');
    }
    setLoading(false);
  }

  const columns = [

    {
      name: 'UDI',
      selector: row => row.udi,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Device Name',
      selector: row => row.deviceName,
      sortable: true,
    },
    {
      name: 'RSSI (dBm)',
      selector: row => row.rssi,
      sortable: true,
    },
    {
      name: 'TX Power (dBm)',
      selector: row => row.txpwr,
      sortable: true,
    },
		{
			name: 'Action',
			cell: row => (row.i) && (
				<FontAwesomeIcon 
					icon={faArrowAltCircleUp} 
					title="Zoom in"
          onClick={() => {
            if (window.leafletMap) {
              window.leafletMap.setView([row.lat, row.lng], 19);
            }
          }}
					style={{ cursor: 'pointer' }}
				></FontAwesomeIcon>
			),
			center: 'true',
		},
    
  ];

  return (
    <>
      <div>
        <FloatingLabel
          controlId="macaddress"
          label="Enter MAC Address"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Enter MAC address"
            className='cInput'
            style={{ width: '250px' }}
          />
        </FloatingLabel>
        <Button type="button" onClick={handleHistory} disabled={loading}>
          {loading ? (
            <>
              <img src={loadlogo} height={20} width={20} alt="Loading" style={{ marginRight: '5px' }} />
              Loading...
            </>
          ) : 'Go'}
        </Button>
      </div>

      <div className="cMapdiv">
        <MapContainer center={position} zoom={6} scrollWheelZoom={true}>
          {iconPosition.length > 0 && <ChangeView positions={iconPosition} />}
          <MapReference />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {iconPosition.map((position, i) => (
            position.lat && position.lng && !isNaN(position.lat) && !isNaN(position.lng) && (
              <Marker 
                position={position}
                icon={createCustomIcon(position.udi ? udiColors.getColor(position.udi) : 'blue')}
                key={'icon_' + i}
              >
                <Popup
                  key={'pop_' + i}>
                  <div>
                    <p><strong>UDI:</strong> {position.udi}</p>
                    <p><strong>Device Name:</strong> {position.deviceName}</p>
                    <p><strong>Date:</strong> {position.date}</p>
                    <p><strong>RSSI:</strong> {position.rssi} dBm</p>
                    <p><strong>TX Power:</strong> {position.txpwr} dBm</p>
                    <p><strong>Number :</strong> {position.i}.</p>
                    
                  </div>
                </Popup>
              </Marker>
            )
          ))}
        </MapContainer>
      </div>

      {iconPosition.length > 1 && (
        <div className="cTableDiv">
          <DataTable
            columns={columns}
            data={iconPosition.slice(1)}
            pagination
            responsive
            highlightOnHover
            striped
            paginationPerPage={10}  
            paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
          />
        </div>
      )}
    </>
  )
}

