import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import moment from 'moment';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Icon } from 'leaflet';

import loadlogo from './images/loading.gif';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
  });
  L.Marker.prototype.options.icon = DefaultIcon;

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export default function Followmac() {
    const [deviceData, setDeviceData] = useState(null);
    const [map, setMap] = useState(null);
    const [bounds, setBounds] = useState(null);
    const defaultPosition = [52.092876, 5.104480]; // Utrecht, Netherlands
    const [positions, setPositions] = useState([]); // Array to store historical positions
    const [loading, setLoading] = useState(false);
    const [macToFollow, setMacToFollow] = useState('');

    useEffect(() => {
        // Get the MAC address from localStorage
        const mac = localStorage.getItem('followMac');
        setMacToFollow(mac);
        
        if (mac) {
            // Initial fetch
            //console.logg('macToFollow:', mac);
            fetchDevicePosition(mac);
        }
    }, []);

    const fetchDevicePosition = async (mac) => {
        //console.logg('fetchDevicePosition:', mac);
        setLoading(true);

        // Available colors array
        const availableColors = [
            'red',    // Instead of '#FF0000'
            'blue',   // Instead of '#0000FF'
            'green',  // Instead of '#00FF00'
            'orange', // Instead of '#FFA500'
            'purple', // Instead of '#800080'
            'yellow', // Instead of '#FFFF00'
            'violet', // Instead of '#FF00FF'
            'grey',   // Instead of '#808080'
            'black',  // Instead of '#000000'
        ];

        const usedColors = new Set();
        const interceptorColors = new Map();
        const defaultColor = 'red'; // Red as default color

        const response = await fetch('https://interceptor.skylab.nl:2807/followmac', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mac: mac,
            })
        });

        if (response.ok) {
            const macdata = await response.json();
            // Process the macdata array
            if (macdata && macdata.length > 0) {
                const newPositions = [];
                // Create markers for each row in macdata
                macdata.forEach(item => {
                    if (item.device_latitude !== null && item.device_longitude !== null && Math.round(item.device_latitude) !== 0 && Math.round(item.device_longitude) !== 0) {
                        // Assign color based on interceptor IMEI
                        if (!interceptorColors.has(item.interceptor_imei)) {
                            // Find first unused color
                            const availableColor = availableColors.find(color => !usedColors.has(color));
                            const currentColor = availableColor || defaultColor;

                            interceptorColors.set(item.interceptor_imei, currentColor);
                            if (availableColor) {
                                usedColors.add(currentColor);
                            }
                        }

                        const currentColor = interceptorColors.get(item.interceptor_imei);
                        item.color = currentColor;
                        const position = {
                            lat: parseFloat(item.device_latitude),
                            long: parseFloat(item.device_longitude),
                            imei: item.interceptor_imei,
                            mac: item.device_mac,
                            rssi: item.rssi,
                            time: moment(item.timestamp).format('HH:mm DD-MM-YYYY'),
                            color: currentColor,
                        };
                        newPositions.push(position);
                    }
                });

                setPositions(newPositions);

                // Find bounds for all positions
                const bounds = findBounds(newPositions);
                setBounds(bounds); // Set the bounds state
            }
        }
        setLoading(false);
    };

    function findBounds(coordinates) {
        //console.logg('coordinates:', coordinates)
        // Initialize bounds with the first coordinate
        let boundsArr = [
            [coordinates[0].lat, coordinates[0].long],  // [lat_min, long_min]
            [coordinates[0].lat, coordinates[0].long]   // [lat_max, long_max]
        ];

        // Loop through remaining coordinates to find min/max values
        for (let i = 1; i < coordinates.length; i++) {
            const coord = coordinates[i];
            // Update maximum values
            boundsArr[1][0] = Math.max(boundsArr[1][0], coord.lat);  // lat_max
            boundsArr[1][1] = Math.max(boundsArr[1][1], coord.long); // long_max

            // Update minimum values
            boundsArr[0][0] = Math.min(boundsArr[0][0], coord.lat);  // lat_min
            boundsArr[0][1] = Math.min(boundsArr[0][1], coord.long); // long_min
        }
        return boundsArr;
    }

    return (
        
        <div style={{ height: '100vh', width: '100%' }}>
            <div>
                <h5>Follow MAC</h5>
                <p>MAC: {macToFollow}</p>
            </div>
            {loading && <div className='cLoading'>
                <p>Loading... Can take a while finding data on mac: {macToFollow}.
                    <img src={loadlogo} height={50} width={50} alt="Loading" />
                </p>
            </div>
            }

            {!loading && <MapContainer
                center={defaultPosition}
                zoom={13}
                style={{ height: '100%', width: '100%' }}
                whenCreated={setMap}
                id="map"
            >
                {/* Add MapUpdater component to handle bounds */}
                {bounds && positions.length > 0 && (
                    <MapUpdater bounds={bounds} />
                )}

                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxZoom={19}
                />
                {/* Current position marker */}
                {positions.map((pos, index) => (
                    <Marker
                        key={`${pos.timestamp}-${index}`}
                        position={[pos.lat, pos.long]}
                        icon={new Icon({
                            iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${pos.color}.png`,
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            popupAnchor: [1, -34],
                            shadowSize: [41, 41],
                          })}
                    >
                        <Popup>
                            <div>
                                <p>Interceptor: {pos.imei}</p>
                                <p>Position:{positions.length - index}</p>
                                <p>MAC: {pos.mac}</p>
                                <p>RSSI: {pos.rssi}</p>
                                <p>Time: {pos.time}</p>
                                <p>Latitude: {pos.lat}</p>
                                <p>Longitude: {pos.long}</p>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>}
        </div>
    );
}

// Add this component at the end of the file
function MapUpdater({ bounds }) {
    const map = useMap();

    useEffect(() => {
        if (bounds) {
            map.fitBounds(bounds, { padding: [50, 50] });
        }
    }, [bounds, map]);

    return null;
} 