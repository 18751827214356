class AirTagDecoder {
  constructor(macAddress, payload) {
    this.bleData = this.constructBLEData(macAddress, payload);
  }

  constructBLEData(macAddress, payload) {
    const macBytes = macAddress.split(":").map(byte => parseInt(byte, 16));
    const payloadBytes = this.hexStringToBytes(payload);
    const filler = Array(31 - payloadBytes.length).fill(0);
    return new Uint8Array([...macBytes, ...payloadBytes, ...filler]);
  }

  hexStringToBytes(hexString) {
    const bytes = [];
    for (let i = 0; i < hexString.length; i += 2) {
      bytes.push(parseInt(hexString.substr(i, 2), 16));
    }
    return bytes;
  }

  decode() {
    const results = [];

    try {
      // Controleer minimale lengte (6 bytes MAC + minimaal 2 bytes payload)
      if (this.bleData.length < 8) {
        throw new Error("Insufficient BLE data length. Expected at least 8 bytes.");
      }

      // MAC Address (6 bytes)
      const macAddressField = this.bleData.slice(0, 6);
      const macAddress = this.decodeMacAddress(macAddressField);

      // Payload
      const payload = this.bleData.slice(6);
      if (payload.length < 2) {
        results.push({
          macAddress,
          status: "Insufficient data to process",
        });
        return results;
      }

      // Extract Company ID and verify it
      const companyId = this.decodeCompanyId(payload.slice(0, 2));
      //if (companyId !== "0x004c") {
        if (companyId !== "0x4c00") {
        results.push({
          macAddress,
          status: "Non-Apple data",
        });
        return results;
      }

      // Decode data fields (example decoding, adjust as needed)
      const dataType = payload[2] || 0x00;
      const statusByte = payload[3] || 0x00;

      const batteryStatus = this.decodeBatteryStatus(statusByte);
      const deviceType = this.decodeDeviceType(statusByte);

      results.push({
        macAddress,
        companyId,
        dataType: this.decodeDataType(dataType),
        batteryStatus,
        deviceType,
        status: "Apple data decoded",
      });
    } catch (error) {
      results.push({ error: error.message });
    }

    return results;
  }

  decodeMacAddress(macField) {
    return Array.from(macField)
      .map(byte => byte.toString(16).padStart(2, "0"))
      .join(":");
  }

  decodeCompanyId(companyIdField) {
    return `0x${companyIdField[0].toString(16).padStart(2, "0")}${companyIdField[1]
      .toString(16)
      .padStart(2, "0")}`;
  }

  decodeBatteryStatus(statusByte) {
    const batteryMap = {
      0x10: "Full",
      0x30: "Medium",
      0x50: "Low",
      0x70: "Critically Low",
    };
    return batteryMap[statusByte & 0xF0] || "Unknown";
  }

  decodeDeviceType(statusByte) {
    const deviceMap = {
      0x01: "Apple Device",
      0x02: "Find My Device",
      0x03: "AirTag",
      0x04: "AirPods",
    };
    return deviceMap[statusByte & 0x0F] || "Unknown Device";
  }

  decodeDataType(dataType) {
    return `0x${dataType.toString(16)}`;
  }
}

export default AirTagDecoder;
