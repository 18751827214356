import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import moment from 'moment';

//https://mui.com/x/react-charts/
import { BarChart } from '@mui/x-charts/BarChart';

import FormSelect from 'react-bootstrap/FormSelect'
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import loadlogo from './images/loading.gif';

export default function Barchart() {
    const [interceptordata, setInterceptordata] = useState([]);
    const [data, setData] = useState([]);
    const [devudi, setDevudi] = useState('')
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false);
    const [chartvisible, setChartvisible] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [lastdatadate, setLastdatadate] = useState();
    const [devCount, setDevCount] = useState([]);
    const [devDate, setDevDate] = useState([]);

    const hourRef = useRef(1);

    const fetchInterceptordata = async (credentials) => {
        setLoading(true)
        setChartvisible(false)
        const response = await axios.post(`https://interceptor.skylab.nl:2807/interceptordatamqtt`,
            {
                udi: credentials,
                hour: hourRef.current,
                startdate: startDate,
                enddate: endDate
            }
        );
        //console.log('response.data:', response.data)
        setInterceptordata(response.data);

        setDevCount(response.data.map(item => item.ble_cnt));
        setDevDate(response.data.map(item => moment(item.prev_fire_time).format('HH:mm:ss DD-MM-YYYY')));

        setLoading(false)
        setChartvisible(true)
    };

    useEffect(() => {
        fetchlastdatadate()
        fetchAccountdevices()
    }, []);


    const setdate = (date) =>{
        console.log('date:', date);
        setStartDate(date);
        if (devudi) {  // Only fetch if a device is selected
            fetchInterceptordata(devudi);
        }
    }

    const setenddate = (date) => {
        setEndDate(date);
        if (devudi) {  // Only fetch if a device is selected
            fetchInterceptordata(devudi);
        }
    }

    function GenDatePicker() {
        return (
            <>
                <input className='cInput' value="Start date:" readOnly='true' ></input>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setdate(date)}
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm"
                    showWeekNumbers
                    maxDate={new Date()}
                    minDate={lastdatadate}
                    showTimeSelect
                />

                <input className='cInput' value="End date:" readOnly='true'></input>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setenddate(date)}
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm"
                    showWeekNumbers
                    maxDate={new Date()}
                    minDate={lastdatadate}
                    showTimeSelect
                />
            </>
        );
    }

    const fetchlastdatadate = async () => {
        try {
            const response = await axios.get(`https://interceptor.skylab.nl:2807/getmqttlastdatadate`);
            setLastdatadate(response.data[0].created_at);
        } catch (error) {
            console.error('Error fetching last data date:', error);
        }
    }

    const fetchAccountdevices = async () => {
        try {
            const items = JSON.parse(localStorage.getItem('token'));
            const { data } = await axios.post(`https://interceptor.skylab.nl:2807/interceptormqtt`,
                { accountid: items.id }
            );

            const results = data.map((value) => ({
                value: value.customerdeviceid,
                label: value.devicename,
                udi: value.udi
            }));

            setOptions([
                { label: 'Select one...', value: '', udi: '', disabled: false },
                ...results
            ]);
        } catch (error) {
            console.error('Error fetching account devices:', error);
        }
    };

    const handleInterceptionSelect = (e) => {
        for (let node of e.target.children) {
            if (node.value === e.target.value) {
                const Udi = node.getAttribute('data-id');
                setDevudi(Udi);
                setChartvisible(true);
                if (startDate && endDate) {  // Only fetch if both dates are set
                    fetchInterceptordata(Udi);
                }
            }
        }
    };

    return (
        <>
            <header className="App-header">
                Interceptor received devices.
            </header>

            {loading && <div className='cLoading'>
                <p>Loading...
                    <img src={loadlogo} height={50} width={50} alt="Loading" />
                </p>
            </div>
            }

            <GenDatePicker></GenDatePicker>

            <FloatingLabel className='cSelector' controlId="selectedInterseptor" label="Interceptor:">
                <FormSelect onChange={handleInterceptionSelect}
                    name="selectedInterseptor"
                    id="selInt">
                    {options.map((option) => (
                        <option 
                            key={option.value} 
                            data-id={option.udi} 
                            disabled={option.disabled}
                        >
                            {option.label + (option.udi ? ' - ' + option.udi : '')}
                        </option>
                    ))}
                </FormSelect>
            </FloatingLabel>



            {chartvisible && <BarChart grid={{ horizontal: true }}
                series={[
                    { data: devCount, showMark: false, label: 'Devices' }
                ]}

                height={390}
                xAxis={[{ data: devDate, scaleType: 'band', tickMinStep: 3600 * 1000 * 24 }]}
                bottomAxis={{
                    tickLabelStyle: {
                        angle: 0,
                        textAnchor: 'middle',
                        // textAlign:'right',
                        // baselineShift:0,
                        // dominantBaseline:'bottom'
                    },
                }}
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />}
        </>
    )
}
