//https://github.com/ms-aija/LeafletReact5minDemo/blob/master/src/components/Map.js#L22

import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from "axios";
import moment from 'moment';
//import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import FormSelect from 'react-bootstrap/FormSelect'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MapBase from './mapbase';
import mqtt from 'mqtt';
import "./maprt.css"
import loadlogo from './images/loading.gif';

const position = [51, 4];
// --- (6) Create a custom marker ---
const customIcon = new Icon({
  iconUrl: 'https://login.iotdash.nl/images/Markers/Origineel/marker-icon-green.png',
  iconSize: [24, 41],
  popupAnchor: [0, -10]
})

export default function Map() {

  const [options, setOptions] = useState([])
  const [devudi, setDevudi] = useState('')
  const [iconPosition, seticonPosition] = useState([])
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [center, setCenter] = useState([52.1460977688048, 5.450848556067135]);
  const [zoom, setZoom] = useState(6);
  const [client, setClient] = useState(null);
  const [accountdevices, setAccountdevices] = useState([]);
  const [showmap, setShowmap] = useState(false);

  const interceptorsRef = useRef({});
  const interceptors = interceptorsRef.current;

  const fetchAccountdevices = async page => {
    setLoading(false);
    const items = JSON.parse(localStorage.getItem('token'));

    const { data } = await axios.post(`https://interceptor.skylab.nl:2807/interceptormqtt`,
      { accountid: items.id }
    );

    const results = []
    data.forEach((value) => {
      results.push({
        value: value.customerdeviceid,
        label: value.devicename,
        udi: value.udi
      });
    });
    // Update the options state
    setOptions([
      { value: 'default', label: 'Select one.', udi: '' },
      ...results
    ])
    setLoading(false);
  };

  useEffect(() => {
    // Only proceed if we have options loaded and client is connected
    if (options.length > 0 && client && isConnected) {
      const storedUdi = localStorage.getItem('selectedUdi');
      if (storedUdi) {
        const matchingOption = options.find(opt => opt.udi === storedUdi);
        if (matchingOption) {
          // Get the select element
          const selectElement = document.getElementById('selInt');
          // Update this selector to match your actual refresh button
          // Only proceed if both elements exist
          if (selectElement) {
            // Set the value
            selectElement.value = matchingOption.value;
            // Simulate onChange event for the select element
            const changeEvent = new Event('change', { bubbles: true });
            selectElement.dispatchEvent(changeEvent);


          }
        }
      }
    }
  }, [options, client, isConnected]);

  const handleInterceptionSelect = (e) => {
    setLoading(true);
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        const udi = node.getAttribute('data-id');
        
console.log(e.target.value)

        // Don't proceed if default option is selected
        if (e.target.value === 'default') {
          setLoading(false);
          setShowmap(false);
          return;
        }

        // Unsubscribe from previous topic if exists
        if (client && devudi) {
          client.unsubscribe(`interceptor/${devudi}/#`);
        }
        
        // Subscribe to new topic immediately
        if (client && udi) {
          client.subscribe(`interceptor/${udi}/#`, { qos: 0 }, (err) => {
            if (err) {
              //console.error("Subscribe failed:", err);
            } else {
              //console.log('Subscribe successful:', `interceptor/${udi}/#`);
              setShowmap(true);
            }
            setLoading(false);
          });
        }
        
        setDevudi(udi);
        break;
      }
    }
  };

  const onMessageArrived = (message) => {
    try {
      const topic = message.destinationName;
      const payload = message.payloadString;

      const parts = topic.split('/');
      if (parts.length >= 2) {
        const imei = parts[1];

        if (!interceptors[imei]) {
          interceptors[imei] = {
            ble_cnt: null,
            fix: null,
            acc: null,
            gpstime: null,
            version: null,
            batt: null,
            gsm: null,
            lat: null,
            long: null,
            spd: null,
            alt: null,
            sats: null,
            heartbeat: 'online'
          };
        }

        const safePayload = payload !== null && payload !== undefined ? payload : null;

        if (parts[2]) {  // Check if we have a third part in the topic
          if (parts[3]) {  // If we have a fourth part, it's a standard metric
            switch (parts[3]) {
              case 'ble_cnt': interceptors[imei].ble_cnt = safePayload; break;
              case 'fix': interceptors[imei].fix = safePayload; break;
              case 'acc': interceptors[imei].acc = safePayload; break;
              case 'gpstime': interceptors[imei].gpstime = safePayload; break;
              case 'version': interceptors[imei].version = safePayload; break;
              case 'batt': interceptors[imei].batt = safePayload; break;
              case 'gsm': interceptors[imei].gsm = safePayload; break;
              case 'lat': interceptors[imei].lat = safePayload; break;
              case 'long': interceptors[imei].long = safePayload; break;
              case 'spd': interceptors[imei].spd = safePayload; break;
              case 'alt': interceptors[imei].alt = safePayload ? (parseFloat(safePayload) / 10).toFixed(2) : null; break;
              case 'sats': interceptors[imei].sats = safePayload; break;
              case 'heartbeat': interceptors[imei].heartbeat = safePayload || 'online'; break;
            }
          }
        }

        const interceptorArray = [
          {
            udi: imei,
            acc: interceptors[imei]['acc'],
            fix: interceptors[imei]['fix'],
            version: interceptors[imei]['version'],
            voltage: interceptors[imei]['batt'],
            batt: calculateBatteryPercentage(interceptors[imei]['batt']),
            gsm: interceptors[imei]['gsm'],
            ble_cnt: interceptors[imei]['ble_cnt'],
            lat: interceptors[imei]['lat'],
            long: interceptors[imei]['long'],
            sats: interceptors[imei]['sats'],
            alt: interceptors[imei]['alt'],
            spd: interceptors[imei]['spd'],
            heartbeat: interceptors[imei]['heartbeat'],
            gpstime: interceptors[imei]['gpstime'],
            status: interceptors[imei]['heartbeat'],
          }
        ];

        if (interceptorArray.length > 0) {
          const newPosition = {
            lat: parseFloat(interceptorArray[0].lat) || 0,
            lng: parseFloat(interceptorArray[0].long) || 0,
            ...interceptorArray[0]
          };
          
          const currentPosition = iconPosition[0];
          if (!currentPosition || 
              currentPosition.lat !== newPosition.lat || 
              currentPosition.lng !== newPosition.lng) {
            seticonPosition([newPosition]);
          }
        }
      }
    } catch (error) {
      console.error('Error processing MQTT message:', error);
    }
  };

  useEffect(() => {
    fetchAccountdevices();

    const mqttClient = mqtt.connect('wss://interceptor.skylab.nl:8883', {
        clientId: `client_${Math.random().toString(16).substring(2, 8)}`,
        keepalive: 30,
        clean: true,
        reconnectPeriod: 5000,
        connectTimeout: 30 * 1000,
        //rejectUnauthorized: false,
        protocolVersion: 4,
        ssl: true
    });

    mqttClient.on('connecting', () => {
        //console.log('Connecting to MQTT broker...');
    });

    mqttClient.on('connect', () => {
        //console.log('MQTT Connected');
        setIsConnected(true);
        setLoading(false);
    });

    mqttClient.on('message', (topic, message) => {
        onMessageArrived({
            destinationName: topic,
            payloadString: message.toString()
        });
        //console.log(topic)
    });

    mqttClient.on('error', (err) => {
        console.log('MQTT Error:', err.message);  // More detailed error logging
        setIsConnected(false);
        setTimeout(() => {
            mqttClient.reconnect();
        }, 5000);
    });

    mqttClient.on('close', () => {
        setIsConnected(false);
    });

    setClient(mqttClient);

    return () => {
        if (mqttClient) {
            mqttClient.end();
        }
    };
  }, []);  // Remove accountdevices dependency

  useEffect(() => {
    if (!isConnected) {
      //console.log('Waiting for MQTT connection...');
    }
  }, [isConnected]);

  function calculateBatteryPercentage(voltage) {
    const maxVoltage = 4.2;
    const minVoltage = 3.5;

    if (voltage >= maxVoltage) {
      return 100;
    } else if (voltage <= minVoltage) {
      return 0;
    } else {
      // Lineaire interpolatie om het percentage te berekenen
      return (((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100).toFixed(0);
    }
  }

  return (
    <>
      <FloatingLabel className='cSelector' controlId="selectedInterseptor" label="Interceptor:">
        <FormSelect onChange={handleInterceptionSelect}
          name="selectedInterseptor"
          id="selInt">
          {options.map((option) => (
            <option 
              key={option.udi || option.value}
              data-id={option.udi}
              value={option.value}
            >
              {option.label + ' - ' + option.udi}
            </option>
          ))}
        </FormSelect>
      </FloatingLabel>

      {loading && <div className='cLoading'>
          <p>Loading...
            <img src={loadlogo} height={50} width={50} alt="Loading" />
          </p>
        </div>
        }

        {showmap && (
          <MapBase 
            iconPosition={iconPosition}
            zoom={zoom}
          />
        )}

    </>
  )
}

